<template>
  <div class="prompt-path">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item v-for="item in breadcrumbdata"
                          :class="item.isactive ? 'active':''"
                          :key="item.name">{{item.name}}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>

export default {
  name: 'Breadcrumb',
  props: {
    // 面包屑数据
    breadcrumbdata: {
      type: Array
    }
  },
  mounted() {
  },
  data() {
    return {
      loginInfo: {}
    }
  },
  created() {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.prompt-path {
  width: 100%;
  height: 50px;
  display: flex;
  padding-left: 20px;
  box-sizing: border-box;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 3px 3px 0px rgba(211, 211, 211, 0.27);
  border-bottom: 10px solid #eef1f7;
  ::v-deep .el-breadcrumb {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #333333;
  }
  ::v-deep .el-breadcrumb__inner {
    color: #333333;
  }
  ::v-deep .el-breadcrumb__item.active .el-breadcrumb__inner {
    color: #385abd;
  }
}
</style>
