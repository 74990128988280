<template>
  <div>
    <Breadcrumb :breadcrumbdata="breadcrumbdata"></Breadcrumb>
    <div class="hash">
      <!-- 接入步骤 -->
      <!-- <div class="access-step" v-if="useTotal == 0"> -->
      <div class="access-step" v-if="showFirstStep">
        <div class="title">接入步骤</div>

        <div class="progressImg">
          <div class="step">
            <div style="clear:both"></div>
            <div class="img">
              <img src="@/assets/plateform/read.png"
                   alt="">
              <div class="fl"> <span class="circle" :class="{ active: apply.state == 2 }">1</span>实名认证 <br/>
                <!-- {{ apply.state && getApplyNameState(apply.state)}} -->
              </div>
            </div>
            <p class="cardText">
              <!-- {{ apply.state == 2 ? "查看" :"请完成"}} -->
              请完成<span class="t1" @click="goCertification">企业及个人实名</span>信息认证</p>
          </div>
          <div class="arrow">
            <div class="line">
              <img src="@/assets/arrow.png" alt="">
            </div>
          </div>
          <div class="step">
            <div style="clear:both"></div>
            <div class="img">
              <img src="@/assets/plateform/step2.png"
                   alt="">
              <div class="fl">
                <!-- <p class="circle" :class="{ active: isbinded == true }">2</p>至信链身份绑定 <br/><span v-if="isbinded">（已绑定)</span> -->
                <p class="circle" :class="{ active: isbinded == true }">2</p>至信链身份绑定
              </div>
            </div>
            <p class="cardText"><span class="t1" @click="bindStatus">绑定链上身份</span><span>后即可进行上链操作</span></p>
          </div>
          <div class="arrow">
            <div class="line">
              <img src="@/assets/arrow.png" alt="">
            </div>
          </div>
          <div class="step step3">

            <div style="clear:both"></div>
            <div class="img">
              <img src="@/assets/plateform/bind.png"
                   alt="">
              <div class="fl">
                <p class="circle" :class="{ active: balance > 0 }">3</p>充值并购买套餐
              </div>
            </div>
            <p class="cardText"><span class="t1" @click="goBuyPackage">购买存证API</span>套餐</p>
          </div>
          <div class="arrow">
           <div class="line">
              <img src="@/assets/arrow.png" alt="">
            </div>
          </div>
          <div class="step step4">
            <div style="clear:both"></div>
            <div class="img">
              <img src="@/assets/plateform/data.png"
                   alt="">
              <div class="fl">
                <p class="circle" :class="{ active: useTotal > 0 }">4</p>调试上链
              </div>
            </div>
             <p class="cardText cardText4">参考<span class="t1" @click="goDocumentcenter">接入文档</span>调试上链</p>
          </div>
        </div>
        <el-divider class="divider"></el-divider>
      </div>
      <template v-if="showTwoPart">
      <!-- <template v-if="useTotal > 0"> -->
        <!-- 存证套餐情况 -->
        <div class="package">
          <div class="title">存证套餐情况</div>
          <div class="package-list">
            <div class="item">
              <div class="img img1">
                <i class="icon icon-shuju"></i>
              </div>
              <div class="tt1">套餐余量</div>
              <div class="tt2">{{usable}}次</div>
              <div class="buy" @click="goBuyPackage">购买</div>
            </div>
            <div class="item">
              <div class="img img2"><i class="icon icon-shuju"></i></div>
              <div class="tt1">套餐已用</div>
              <div class="tt2">{{use}}次</div>
            </div>
            <div class="item">
              <div class="img img3"><i class="icon icon-shuju"></i></div>
              <div class="tt1">本月已用</div>
              <div class="tt2">{{thisMounthUseTime}}次</div>
            </div>
          </div>
        </div>
        <el-divider class="divider"></el-divider>
        <!-- 使用进度 -->
        <div class="speed" v-if="getUserIdentity != 1">
          <div class="title">使用进度</div>
          <div class="remain" v-if="this.hashTableData && this.hashTableData.length">以当前速度预计还能使用 {{estimate}} 天</div>
          <div class="progress">
            <el-progress :text-inside="true"
                        color="#3a5cbf"
                        :stroke-width="16"
                        :percentage="percent"></el-progress>
          </div>
        </div>

        <el-divider v-if="getUserIdentity != 1" class="divider"></el-divider>
        <!-- Hash存证记录 -->
        <div class="record">
          <div class="title">Hash存证记录</div>
          <div>
            <el-form :inline="true"
                    :model="hashForm"
                    class="hashForm">
              <el-form-item label="时间筛选：">
                <el-date-picker v-model="hashForm.selTimes"
                                class="sel-time"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="pickerOptions">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="关键字查询："
                            class="msearch">
                <el-input v-model="key"
                          clearable
                          class="search-input"
                          placeholder="存证hash/交易hash/evid"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button icon="el-icon-search"
                          class="sumbtn"
                          @click="searchList">查询</el-button>
              </el-form-item>
            </el-form>
            <el-table v-if="hashTableData.length" :data="hashTableData"
                      stripe
                      class="hash-table"
                      style="width: 100%">
              <el-table-column prop="evHash"
                              align="left"
                              label="存证Hash">
                              <template slot-scope="scope">
                                <span class="hash-item">{{scope.row.evHash}}</span>
                              </template>
              </el-table-column>
              <el-table-column prop="txHash"
                              align="left"
                              label="交易Hash">
              </el-table-column>
              <el-table-column prop="evId"
                              align="center"
                              width="100"
                              label="Evid">
              </el-table-column>
              <el-table-column prop="evTime"
                              align="center"
                              width="180"
                              label="存证时间">
              </el-table-column>
              <el-table-column fixed="right"
                              align="center"
                              label="操作"
                              width="120">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="showDetail(scope.row)"
                            type="text"
                            size="small">
                    证书详情
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
             <div v-if="total > 0">
                <Pagination
                :total="total"
                :pageSize="count"
                @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange"
                :currentPage="start"
              ></Pagination>
             </div>


           <!-- 如果是查询的单条数据添加返回充值按钮   -->
           <div style="margin-top: 20px;text-align: center;"  v-if="hashTableData.length == 1">
                          <el-button class="research-button" @click="toBackList">返回</el-button>
           </div>

         <div class="no-data-wrap" v-if="!hashTableData.length">
          <div class="tc"><i class="icon icon-no-data icon-size"></i></div>
          <div class="no-data-desc tc">未找到相关数据</div>
          <!-- <el-button class="research-button" @click="toBackList">返回</el-button> -->
        </div>

          </div>
        </div>
      </template>
    </div>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
        custom-class="search-dialog"
       >
        <div class="content">
          <div>
            <i class="icon icon-tishi-2"></i>
          </div>
          <div class="t2">请选择查询条件</div>
        </div>
        <span slot="footer" class="dialog-footer">
           <el-button class="sub-ok-btn" type="primary" @click="dialogVisible = false">确 定</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
import Breadcrumb from '@/components/common/Breadcrumb'
import {  getUserInfo, getApply, checkIdCard ,getAppDetail} from '@/api/user'
import { getCurrentPackageTotal, getThisMounthPackageTotal,getEvidenceList,getBalance, getStatisticTotal } from '@/api/overview'
import Pagination from '@/components/common/Pagination.vue'
import moment from 'moment'
import { setInterval, clearInterval } from 'timers';
import { statusCode } from "@/config";
export default {
  name: 'overview',
  components: {
    Breadcrumb,
    Pagination
  },
  data() {
    return {
      isAgent:false, // 是否是代理商身份
      query: null,
      p1:null,
      p2:null,
      p3:null,
      useTotal:-1,//使用开放平台服务次数 调试上链成功之后 次数增加
      balance:'', // 账户🈷️
      isbinded: true, // 是否是身份绑定
      timer:null,
      isShowStep:true,// 接入步骤 完成上链存证后，隐藏“接入步骤”
      isBuyPackage:false,// 是否是购买了套餐
      dialogVisible:false,
      startTime:'',
      endTime:'',
      key:'', // 关键字
      total: 0, // 总页数
      count:10, // 每页条数
      start:1, // 开始页面
      userData:{},
      exist: null,
      apply: {
      },
      usable  :0,
      use     :0,
      percent :0,
      estimate:0,
      thisMounthUseTime:0,
      breadcrumbdata: [
        {
          path: '/',
          name: '开放平台'
        },
        {
          name: '存证总览',
          path: '/',
          isactive: true
        }
      ],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      hashForm: {
        selTimes: [moment(moment().subtract(7, 'days'),'YYYY-MM-DD').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')], // 时间范围
      },
      hashTableData: [
        // {
        //   evHash: 'ed13281fcb736077e89357b90ef6b8b003453e7fed190ccc5191bbd288e0d2f4',
        //   txHash: 'ed13281fcb736077e89357b90ef6b8b003453e7fed190ccc5191bbd288e0d2f4',
        //   evId: '200',
        //   evTime: '2017-10-01 12:00',
        //   accessCode:'0f677c4c0455512de5bd5658594f6a6c693dfdae181f4286bbd7dac8417a052308200c2ed0aff15ec91656aa503792075359697a7c2956b9f0514fb8fa2f2355f5256493476e2d1399aed342dd6197cd'
        // }
      ]
    }
  },
  created(){
     // 设置详情页面可以返回
      let req = null
      if (sessionStorage.getItem('openQuery') && JSON.parse(sessionStorage.getItem('openQuery'))!= null) {
        req = sessionStorage.getItem('openQuery')
        this.query = JSON.parse(req)
        this.query.startTime = this.query.startTime || moment(moment().subtract(7, 'days'),'YYYY-MM-DD').format('YYYY-MM-DD')
        this.query.endTime = this.query.endTime ||  moment().format('YYYY-MM-DD')
        // 赋予值
        this.hashForm.selTimes = [this.query.startTime || moment(moment().subtract(7, 'days'),'YYYY-MM-DD').format('YYYY-MM-DD'),this.query.endTime ||  moment().format('YYYY-MM-DD')]
        this.key = this.query.key
        this.count = this.query.count
        this.start = this.query.start
      } else {
        req = {
          startTime:this.hashForm.selTimes && moment(this.hashForm.selTimes[0]).format('YYYY-MM-DD') || '', // format('YYYY-MM-DD h:mm:ss')
          endTime: this.hashForm.selTimes && this.hashForm.selTimes[1] &&  moment(this.hashForm.selTimes[1]).format('YYYY-MM-DD') || '',
          key:this.key,
          count:this.count,
          start:this.start
        }
        this.query = JSON.parse(JSON.stringify(req))
      }
  },
  mounted() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.idCardCheck()
      this.getUserInfo().then(()=>{
        if(this.userData.epId != 0){
          this.getApply().then(()=>{
            if(this.apply.state == 2){
                 this.getAppDetail().then(()=>{
                    this.getThisMounthPackageTotal()
                    //开放平台当前用户套餐使用情况
                    this.getCurrentPackageTotal()
                 })
            }else{
              this.isbinded = false; // 身份绑定值重置值
            }
          })
        }else{
             this.isbinded = false; // 身份绑定值重置值
        }
      })
      // this.idCardCheck().then(()=>{
      //   if(this.exist == 1 && this.apply.state == 2){
      //       this.getAppDetail().then(()=>{
      //           // if(this.isbinded){
      //               // 开放平台当前用户本⽉使⽤用统计
      //               this.getThisMounthPackageTotal()
      //               //开放平台当前用户套餐使用情况
      //               this.getCurrentPackageTotal()
      //           // }
      //       })
      //   }
      // }) 

      // Promise
      // Promise.all()
     
      this.getBalance() // 3
     if (userData) {
      if (userData.epNum === '') {
        this.name = userData.email
      } else {
        this.name = userData.contact
      }
    }


     // 前三步走完了 在查询存证数据
     // 获取hash 存证记录

    // 开放平台当前⽤户使⽤用次数及详情
    this.getStatisticTotal().then(()=>{
      if(this.useTotal > 0){
          this.getEvidenceList()
      }
    })

  },
   beforeRouteLeave(to, from, next) {
     console.log('to',to)
    // 设置下一个路由的 meta
    if (to.name !== 'hashcertificatedetail') {
       sessionStorage.removeItem('openQuery')
    }
    next()
  },

  // 首次进入 报错修复 2021.3.27
  // beforeRouteEnter(to, from, next) {
  //    next(vm =>{
  //      if(from.path == '/'){
  //         vm.toBackList()
  //      }
  //      next()
  //    })   
  // },
  beforeUpdate() {
    sessionStorage.setItem('openQuery', JSON.stringify(this.query))
  },
  computed: {
    getUserIdentity() {
      return JSON.parse(localStorage.getItem('userData')).customerChannel
    },
    showTwoPart(){
      // 腾讯内部用户 1无法跳过购买套餐这一步，直接可以看见存证套餐情况
      let roleId = JSON.parse(localStorage.getItem('userData')).customerChannel
      if( roleId == 1 && this.isbinded){
          return true
      }
      return this.balance > 0 || this.usable > 0 || this.use > 0 || this.thisMounthUseTime > 0
    },
    // 展示接入步骤 
    showFirstStep(){
       return this.exist == 0 || this.useTotal == 0 || !this.isbinded
    },
    // showSecondStep() {
    //   return this.isbinded && this.exist && this.useTotal > 0
    // }
  },
  watch:{
    isBuyPackage(val){
      if(val){
        // 是否是购买了套餐 每一个小时刷新一次  1*60*60*1000
        this.timer = setInterval(this.getCurrentPackageTotal, 1*60*60*1000)
      }
    }
  },
  methods: {
    getApplyNameState (key){
      let obj = {
        1:'(审核中)',
        2:'(已实名)',
        3:'(未通过)',
      }
      return obj[key]
    },
    toBackList(){
      this.key = ''
      this.count = 10
      this.start = 1
      this.hashForm.selTimes = [moment(moment().subtract(7, 'days'),'YYYY-MM-DD').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      this.query.start = 1
      this.query.count = 10
      this.query.key = ''
      this.query.startTime = this.hashForm.selTimes && moment(this.hashForm.selTimes[0]).format('YYYY-MM-DD') || '', // format('YYYY-MM-DD h:mm:ss')
      this.query.endTime = this.hashForm.selTimes && this.hashForm.selTimes[1] &&  moment(this.hashForm.selTimes[1]).format('YYYY-MM-DD') || '',

      this.getEvidenceList()
    },
    // 开发平台使用次数
   getStatisticTotal(){
        this.p3 = new Promise((resolve,rej)=>{
            getStatisticTotal().then((res) => {
              if (res.retCode === 0) {
                this.useTotal = res.total
                console.log("p3")
                resolve(res.total)
                // console.log("this.useTotal",res.total)
              }else{
                this.$message.error(res.retMsg)
                rej()
              }
            })
        })
        return this.p3
    },
      // 获取账户余额
    getBalance() {
     return getBalance().then(res => {
        this.balance = res.detail.balance
      })
    },
    getAppDetail() {
      const that = this;
        this.p2 =new Promise((resolve,rej)=>{
       getAppDetail().then(res => {
        this.$message.closeAll();
        if (res.retCode == 0) {
          if (res.detail.length > 0) {
            if (res.detail[0].appTid === "") {
              that.isbinded = false;
            } else if (
              res.detail[0].epPubKey === "" &&
              res.detail[0].appId !== ""
            ) {
              that.isbinded = false;
            } else if (
              res.detail[0].epPubKey !== "" &&
              res.detail[0].appId !== ""
            ) {
              that.isbinded = true;
            }
          } else {
            that.isbinded = false;
          }
          resolve(that.isbinded)
        } else {
          this.$message.error(res.retMsg);
          rej()
        }
      });
     })

     return this.p2

    },
    handleSizeChange(val) {
      console.log("handleSizeChange",val)
      this.query.count = val
      this.count = val
      this.getEvidenceList()
    },
    handleCurrentChange(val) {
      this.start = val
      this.query.start = val
      this.getEvidenceList()
    },
    searchList(){
      this.start = 1
      this.count = 10
      this.query.start = 1
      this.query.count = 10
      this.query.key = this.key
      this.query.startTime = this.hashForm.selTimes && moment(this.hashForm.selTimes[0]).format('YYYY-MM-DD') || '', // format('YYYY-MM-DD h:mm:ss')
      this.query.endTime = this.hashForm.selTimes && this.hashForm.selTimes[1] &&  moment(this.hashForm.selTimes[1]).format('YYYY-MM-DD') || '',
      this.getEvidenceList()
    },
    getEvidenceList(){
      console.log('555',this.hashForm.selTimes)
      if(!this.hashForm.selTimes && !this.key){
        this.dialogVisible = true
        return
      }
      // const params = {
      //   startTime:this.hashForm.selTimes && moment(this.hashForm.selTimes[0]).format('YYYY-MM-DD') || '', // format('YYYY-MM-DD h:mm:ss')
      //   endTime: this.hashForm.selTimes && this.hashForm.selTimes[1] &&  moment(this.hashForm.selTimes[1]).format('YYYY-MM-DD') || '',
      //   key:this.key,
      //   count:this.count,
      //   start:this.start
      // }
       getEvidenceList(this.query).then((res) => {
           if (res && res.retCode === 0) {
             this.hashTableData = res.detail || []
             if(this.hashTableData.length){
                this.hashTableData.forEach(element => {
                  const _index = element.evTime.indexOf('.')
                  if(_index > -1){
                      element.evTime = element.evTime.substring(0,_index)
                  }
                });
             }
             this.total = res.total
            // res.detail && (this.isShowStep = false)
           }else{
             //4518->数据过多，请缩小查询范围
            //  this.$message.error(res.retMsg)
             this.$message.error(statusCode[res.retCode])
             
           }
        })
    },

    getThisMounthPackageTotal(){
       getThisMounthPackageTotal().then((res) => {
           if (res.retCode == 0) {
             if(!res.detail){
               return
             }
             const filterdata = res.detail.filter((item)=>{
               return item.busiDetailType = 2 
             })
             this.thisMounthUseTime = filterdata[0].total || 0
           }else{
             this.$message.error(res.retMsg)
           }
        })
    },

    getCurrentPackageTotal(){
       getCurrentPackageTotal().then((res) => {
           if (res.retCode == 0 ) {
             if(!res.detail){
               return
             }
             // 过滤出存证的状态 2
             const filterdata = res.detail.filter((item)=>{
               return item.busiDetailType = 2 
             })
             if(filterdata.length){
                this.isBuyPackage = true
                this.usable = filterdata[0].usable
                this.use = filterdata[0].use
               //  this.percent = filterdata[0].percent * 100
                this.percent = Number((filterdata[0].percent *100).toString().match(/^\d+(?:\.\d{0,2})?/))
                this.estimate = filterdata[0].estimate
             }
           }else if(res.retCode === 2002){
             // this.isBuyPackage = false
           }else{
             this.$message.error(res.retMsg)
           }
        })
    },
    // 展示详情
    showDetail(row) {
      console.log("row",row)
      this.$router.push({ path: '/hashcertificate/detail',query:{
        accessCode:row.accessCode,
        isAgent:this.userData.isAgent || false
      } })
    },
    // 进入企业实名认证
    goCertification(){
      this.$router.push({ name: 'enterpriseInfo' })
    },
    // 接入文档
    goDocumentcenter(){
      this.$router.push({ name: 'documentcenter' })
    },

    // 购买存证套餐
    goBuyPackage(){
      // isShowBuy
       window.open(window.toolbox_redirectUrl.wallet + '?isShowBuy=true')
    },
    // 绑定身份
    bindStatus(){
       const userData = this.userData
      // const apply = this.apply
       if (userData.epId === 0) {
          this.$router.push({ path: '/enterpriseInfo' })
        } else if (userData.epId !== 0) {
          if (this.exist == 0) {
            this.$router.push({ path: '/applicantAuth' })
          } else {
            this.$router.push({ path: '/bind' })
          }
        }
    },

    // 提交审核
    onSubmit(){

    },
    getUserInfo() {
      return getUserInfo().then((res) => {
        if (res.retCode == 0) {
          this.userData = res.detail
          console.log("res.detail",res.detail)
          if (res.detail.epId != 0) {
            this.getApply()
          }
        } else {
          this.$message.error(res.retMsg)
        }
      })
    },
    getApply() {
      const that = this
     return getApply().then((res) => {
        this.$message.closeAll()
        if (res.detail) {
          that.apply = res.detail
          localStorage.setItem('userDetail', JSON.stringify(res.detail))
        } else {
          this.$message.error(res.retMsg)
        }
      })
    },

    // 检验企业申请人实名信息是否通过
    idCardCheck() {
        this.p1 =new Promise((resolve,rej)=>{
            checkIdCard().then((res) => {
              this.$message.closeAll()
              if (res.retCode === 0) {
                console.log("this.exist",this.exist)
                this.exist = res.exist
                resolve(res.exist)
              } else {
                this.$message.error(res.retMsg)
                rej()
              }
            })
         })
         return this.p1
    },


  },
   beforeDestroy() {
      clearInterval(this.timer)
   }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header{
    border-bottom: 1px solid #ddd;
}
::v-deep .el-dialog__body{
  padding-top:25px;
}
::v-deep .el-dialog__footer{
  text-align: center;
  padding-top:0px;
  padding-bottom: 30px;
  .el-button{
    width: 130px;
    height: 40px;
    border-radius: 20px;
  }
  .sub-ok-btn{
    background: #1f50ab;
    margin-right: 20px;
  }
}
.hash {
  background: #fff;
  padding: 35px 30px;
  .title {
    font-size: 18px;
    padding-left: 15px;
    border-left: 4px solid #2455ce;
  }
  .search-input{
    width: 250px;
  }
  .divider {
    margin: 30px 0;
  }
  .access-step {
    .progressImg {
      // width: 1000px;
      margin: 10px auto 0;
      display: flex;
      margin-bottom: 50px;
      .step {
        position: relative;
        flex: 0 0 150px;
        width: 150px;
        background: #fff;
        .circle {
          display: inline-block;
          width: 27px;
          height: 27px;
          border-radius: 27px;
        //  background: #d6ddf1;
        //@at-root  color: #6682d1;
           border:1px solid #999;
          color: #999;
          line-height: 27px;
          text-align: center;
          margin: 5px 8px 0 0;
        }
        .active {
         // background-color: #6682d1;
          background-color:#4066d0;
          color: #fff;
        }
        .img {
          text-align: left;
          margin-top: 5px;
          img {
            height: 71px;
            margin-left: 35px;
          }
          p {
            font-size: 18px;
          }
        }
        .cardText {
          text-align: left;;
          padding: 0 0px;
          margin: 6px 0 0 0;
          padding-left: 37px;
          color: #354657;
          position: absolute;
          width: 244px;
          font-size: 16px;
          a {
            color: #4066d0;
            text-decoration: none;
          }
          .t1{
            color: #4066d0;
            cursor: pointer;
            text-decoration: none;
          }
        }
        .cardText4{
             width: 200px;
        }
      }
      // .step3{
      //   flex: 0 0 171px;
      //   width: 171px;
      // }
       .step4{
        flex: 0 0 240px;
        width: 240px;
      }
      .arrow {
        flex: 1;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        .line{
          height: 0px;
          flex: 1;
          border-top: 1px dashed #b5c7f8;
          text-align: center;
          position: relative;
          img{
            margin-top: -9px;
            position: absolute;
            left: 50%;
            margin-left: -5px;
          }
        }
      }
    }
  }
  .package {
    .package-list {
      padding: 0 20px;
      display: flex;
      margin-top: 35px;
      justify-content: space-between;
    }
    .item {
      width: 32%;
      height: 192px;
      background-image: linear-gradient(0deg, #ebf2ff 0%, #fdfeff 100%),
        linear-gradient(#fdfeff, #fdfeff);
      background-blend-mode: normal, normal;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .img {
        width: 78px;
        text-align: center;
        line-height: 78px;
        border-radius: 50%;
        height: 78px;
      }
      .icon{
        font-size: 40px;
        color: #fff;
      }
      .img1{
        background-color: #90aaeb;
      }
      .img2{
        background-color: #dec285;;
      }
       .img3{
        background-color: #7abbbc;;
      }
      .tt1 {
        margin-top: 10px;
        font-size: 14px;
        color: #999999;
      }
      .tt2 {
        font-size: 28px;
        font-weight: bold;
        color: #030303;
      }
      .buy {
        cursor: pointer;
        margin-top: 5px;
        text-align: center;
        width: 60px;
        height: 24px;
        line-height: 24px;
        border-radius: 4px;
        border: solid 1px #385abd;
        font-size: 14px;
        color: #385abd;
      }
    }
  }
  // 使用进度
  .speed {
    .remain {
      text-align: right;
      height: 14px;
      font-size: 14px;
      letter-spacing: 0px;
      color: #999999;
      margin-right: 20px;
    }
    .progress {
      margin-top: 25px;
      padding: 0 20px;
      margin-bottom: 58px;
    }
  }

  // Hash存证记录
  .record {
    .msearch {
      margin-left: 20px;
      margin-right: 20px;
    }
    .sel-time{
      width: 253px
    }
    .hashForm {
      margin-top: 20px;
      ::v-deep .el-form-item__label {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 48px;
        letter-spacing: 0px;
        color: #222222;
      }
    }

    ::v-deep .el-table th > .cell {
        font-size: 14px;
        color: #222222 !important;
    }

    .sumbtn {
      color: #385abd;
      height: 40px;
      border-radius: 4px;
      border: solid 1px #385abd;
    }
  }
}
.search-dialog{
  .content{
    text-align: center;
    .t2{
      margin-top: 10px;
    }
  }
  .icon{
    font-size: 80px;
    color: #f3a95d;
  }
}
.no-data-wrap {
  text-align: center;
}
.tc {
  text-align: center !important;
}
.icon-size {
  font-size: 120px;
}
.no-data-desc {
  padding: 15px 0 15px 0;
}
.research-button {
  color: #fff;
  margin-bottom: 20px;
  width: 90px;
  background-color: #2b4ca0;
}
.fl{
  text-align: left
}
</style>
