<template>
  <div class="z-pagination">
    <el-pagination
      background
      :prev-text="prevtext"
      :next-text="nexttext"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100, 200]"
      :page-size="pageSize"
      :layout="layout"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  props: {
    // 翻页文字
    prevtext: {
      type: String,
      default: '上一页',
    },
    // 翻页文字
    nexttext: {
      type: String,
      default: '下一页',
    },
    // 当前页
    currentPage: {
      type: Number,
      default: 1,
    },
    // 总条数
    total: {
      type: Number,
      default: 1,
    },
    // 每页显示条数
    pageSize: {
      type: Number,
      default: 10,
    },
    // 分页显示的内容
    layout: {
      type: String,
      default: 'total, prev, pager, next, sizes,jumper',
    },
  },
  components: {},
  data() {
    return {
    }
  },
  computed: {},
  mounted() {},
  methods: {
    // 当前页改变触发的方法
    handleCurrentChange(val) {
      this.$emit('handleCurrentChange', val)
    },
    handleSizeChange(val) {
      this.$emit('handleSizeChange', val)
    },
  },
}
</script>
<style lang="scss" scoped>
.z-pagination {
  margin-top: 40px;
  // padding-left: 150px;
  margin-bottom: 20px;
  box-sizing: border-box;
  text-align: center;
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #385abd;
  }
  ::v-deep .el-pagination.is-background .el-pager li {
    // width: 35px;
    height: 35px;
    line-height: 35px;
    box-sizing: border-box;
  }
  ::v-deep .el-pagination.is-background .btn-prev,
  ::v-deep .el-pagination.is-background .btn-next {
    height: 35px;
    padding: 0 20px;
    background-color: #fff;
    border-radius: 2px;
    border: solid 1px #e7e7e7;
  }
  ::v-deep .el-pagination.is-background .el-pager li {
    background-color: #fff;
    border-radius: 2px;
    border: solid 1px #e7e7e7;
  }
  ::v-deep .el-pagination__sizes {
    height: 35px;
    .el-select {
      height: 35px;
      .el-input {
        height: 35px;
        .el-input__inner {
          height: 35px;
        }
      }
    }
  }
  ::v-deep .el-pagination__jump {
    margin-left: 1px;
    .el-pagination__editor.el-input .el-input__inner {
      height: 35px;
    }
  }
}
</style>
