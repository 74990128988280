/*
 * @Author: your name
 * @Date: 2020-04-29 10:51:58
 * @LastEditTime: 2020-07-13 17:44:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /frontend-toolbox/src/api/overview.js
 */
import axios from '@/axios'
import $http from "axios"
// 获取客户列表
// export const customerManagementList = data => axios.post('/admin/ep/list', data)
// export const getCategory = data => axios.get(`/trade/parent?pid=${data}`)


// 开放平台当前⽤户使⽤用次数及详情 Https://ip:port/zhixin-api/v2/ump/openplatform/busi/statistic/total
export const getStatisticTotal = data => axios.get('/api/ump/openplatform/busi/statistic/total')


// 一段时间当前用户服务使用情况  Https://ip:port/zhixin-api/v2/ump/openplatform/busi/statistic/time/list
export const getStatisticTimeList = data => axios.get(`/api/ump/openplatform/busi/statistic/time/list?busiDetailType=${data.busiDetailType}&listType=${data.listType}`)


// 开放平台当前⽤用户套餐使用情况 Https://ip:port/zhixin-api/v2/ump/openplatform/busi/package/current/statistic/total
export const getCurrentPackageTotal = data => axios.get('/api/ump/openplatform/busi/package/current/statistic/total')

// 开放平台当前用户本⽉使⽤用统计 Https://ip:port/zhixinapi/v2/ump/openplatform/statistic/thismoth/total
export const getThisMounthPackageTotal = data => axios.get('/api/ump/openplatform/busi/statistic/thismonth/total')

// 当前用户存证记录按条件列列表  Https://ip:port/zhixin-api/v2/ump/openplatform/busi/evidence/list
export const getEvidenceList = data => axios.get(`/api/ump/openplatform/busi/evidence/list?startTime=${data.startTime}&endTime=${data.endTime}&key=${data.key}&count=${data.count}&start=${data.start}`)


// 开放平台当前用户本月消费金额  Https://ip:port/zhixinapi/v2/ump/openplatform/statistic/thismoth/totalprice
// export const getThisMounthTotalPrice = () => axios.get('/api/ump/openplatform/busi/statistic/thismonth/totalprice')

//钱包 当前登录用户余额信息查询 Https://ip:port/zhixin-api/v2/wallet/balance
export const getWalletBalance = (data) => axios.get('/api/ump/wallet/balance')

//钱包 当前登录用户本⽉消费查询 Https://ip:port/zhixin-api/v2/wallet/spend
//                        /busi/statistic/thismonth/totalprice
export const getWalletSpend = (data) => axios.get('/api/ump/openplatform/busi/statistic/thismonth/totalprice')

//钱包 当前登录用户取证套餐余量 Https://ip:port/zhixin-api/v2/wallet/package/evobtain/usable
//                        https://testwww.zhixinblockchain.com/api/ump/appbox/busi/package/current/statistic/total?timestamp=1591671696921
export const getEvobtainUsable = () => axios.get('/api/ump/appbox/busi/package/current/statistic/total')

// 钱包 获取当前用户余额
export const getBalance = data => axios.get(`/api/ump/wallet/balance`)


// 获得证书详情 https://testwww.zhixinblockchain.com/api/evidence-cert?accessCode
export const getEvidenceCert = data => axios.get(`/api/evidence-cert?accessCode=${data.accessCode}`)