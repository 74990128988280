import axios from '@/axios'
import axioss from 'axios'
import { setCookie,getCookie,delCookie,getUuid } from "@/utils"
// 注册
export const register = data => axios.post('/api/ump/user/signup', data)
// 登录
export const login = data => axios.post('/api/ump/user/signin', data)
// 查询用户信息
export const getUserInfo = data => axios.get('/api/ump/user/info')
// 发送邮箱验证码
export const sendCode = data => axios.get(`/api/ump/user/sendcode?email=${data.email }&type=${data.type}`, data)
// 提交客服申请
export const sendQuestion = data => axios.post('/api/ump/user/question', data)
// 上传营业执照
export const uploadDocument = data => axios.post('/api/ump/ep/upload', data)
// 修改密码
export const modifyPass = data => axios.post('/api/ump/user/update', data)
// 忘记密码
export const forgetpwd = data => axios.post('/api/ump/user/forgetpwd', data)
// 企业认证信息提交
export const sendApply = data => axios.post('/api/ump/ep/apply', data)
// 企业认证信息修改
export const updateApply = data => axios.post('/api/ump/ep/update', data)
// 获取行业纷类父级
export const getTradeParent = data => axios.get(`/api/ump/trade/parent?pid=${data.pid}`)
// 获取行业纷类子级
export const getTradeChild = data => axios.get(`/api/ump/trade/child?pid=${data.pid}`)
// 企业认证信息查询
export const getApply = data => axios.get('/api/ump/ep/get')
// 用户登出
export const loginout = data => axios.get('/api/ump/user/logout')
// 检验企业申请人实名信息是否通过
export const checkIdCard = data => axios.get('/api/ump/user/idcardcheck')
// 企业营业执照下载
export const downloadbusiLicense = data => axioss.get(`/api/ump/ep/download?busiLicenseId=${data}`, {
  responseType: 'arraybuffer',
  headers: {
    'X-XSRF-TOKEN': getCookie('zxtoken') ? decodeURIComponent(getCookie('zxtoken')) : '',
    'Cloud-Trace-Id': getUuid()
  }
})
// 电子公函盖章扫描件下载
export const downloadletter = data => axioss.get(`/api/ump/ep/downloadletter?letterId=${data}`, {
  responseType: 'arraybuffer',
  headers: {
    'X-XSRF-TOKEN': getCookie('zxtoken') ? decodeURIComponent(getCookie('zxtoken')) : '',
    'Cloud-Trace-Id': getUuid()
  }
})
// 身份证正面下载
export const downloadCardFront = data => axioss.get(`/api/ump/ep/downloadidcardfront?id=${data}`, {
  responseType: 'arraybuffer',
  headers: {
    'X-XSRF-TOKEN':getCookie('zxtoken') ? decodeURIComponent(getCookie('zxtoken')) : '',
    'Cloud-Trace-Id': getUuid()
  }
})
// 身份证正面下载
export const downloadCardBack = data => axioss.get(`/api/ump/ep/downloadidcardback?id=${data}`, {
  responseType: 'arraybuffer',
  headers: {
    'X-XSRF-TOKEN': getCookie('zxtoken') ? decodeURIComponent(getCookie('zxtoken')) : '',
    'Cloud-Trace-Id': getUuid()
  }
})
// 证据文件下载
export const downloadWebevid = data => axioss.get(`/api/ump/evobtain/webevid/download?id=${data}`, {
  responseType: 'arraybuffer',
  headers: {
    'X-XSRF-TOKEN': getCookie('zxtoken') ? decodeURIComponent(getCookie('zxtoken')) : '',
    'Cloud-Trace-Id': getUuid()
  }
})
// 电子保管函（证书）下载
export const downloadVoucher = data => axioss.get(`/api/ump/evobtain/voucher/download?id=${data}`, {
  responseType: 'arraybuffer',
  headers: {
    'X-XSRF-TOKEN': getCookie('zxtoken') ? decodeURIComponent(getCookie('zxtoken')) : '',
    'Cloud-Trace-Id': getUuid()
  }
})
// 用户身份信息下载
export const downloadUser = data => axioss.get(`/api/ump/evobtain/user/download?id=${data}`, {
  responseType: 'arraybuffer',
  headers: {
    'X-XSRF-TOKEN':getCookie('zxtoken') ? decodeURIComponent(getCookie('zxtoken')) : '',
    'Cloud-Trace-Id': getUuid()
  }
})
// 发送短信验证码
export const sendPhoneCode = data => axios.post('/api/ump/ep/sendcode', data)
// 企业应用查询
export const getAppDetail = data => axios.get('/api/ump/app/list')
// 企业应用创建
export const creatGen = data => axios.post('/api/ump/app/gen', data)
// 企业应用公钥上传
export const uploadCert = data => axios.post('/api/ump/cert/upload', data)
// 企业应用公钥更新
export const updateCert = data => axios.post('/api/ump/cert/update', data)
// 发送公钥更新邮件
export const sendCertCode = data => axios.get('/api/ump/cert/sendcode')
// 发送公钥更新邮件
export const checkEmail = data => axios.get(`/api/ump/user/check?email=${data.email}`)
// 获取证据列表
export const evidenceList = data => axios.get('/api/ump/evobtain/list', {
  params: data
})
// 新建证据
export const newEvidence = data => axios.post('/api/ump/evobtain/obtain', data)
// 获取证据详情
export const evidenceDetail = data => axios.get(`/api/ump/evobtain/get?id=${data}`)

// 验证企业信息是否正确 
// GET  /zhixin-api/v2/ump/ep/companyverify?creditCode=10086&epName=et_test&representative=representative_test
export const companyVerify = data => axios.get(`/api/ump/ep/companyverify?creditCode=${data.creditCode}&epName=${data.epName}&representative=${data.representative}`)